"use client";
import { Button, Card, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import * as Sentry from "@sentry/nextjs";
import React from "react";

class ErrorBoundary extends React.Component<{
  children: React.ReactNode;
}> {
  state: { hasError: boolean; error: string };
  constructor(props: { children: React.ReactNode }) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false, error: "" };
  }
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch(error: Error, errorInfo: unknown) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
    Sentry.captureException(error);

    this.setState({ error: error.message });
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100vh",
            gap: 2,
          }}
        >
          <Typography variant="h2">Oops, there is an error!</Typography>
          <Card variant="outlined" elevation={0} sx={{ p: 2, mt: 2, w: "100%" }}>
            <Typography variant="h4">Error details:</Typography>
            <Typography variant="body1">{this.state.error}</Typography>
          </Card>
          <Button type="button" onClick={() => this.setState({ hasError: false })}>
            Try again?
          </Button>
        </Box>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
