"use client";
import { Box, Button } from "@mui/material";
import { UserSubscription } from "@prisma/client";
import { ArrowLeft } from "@untitled-ui/icons-react";
import { useRouter } from "next-nprogress-bar";
import { useGetUserPlan } from "~/app/hooks/useGetUserPlan";
import { UpgradeButton } from "../upgrade/button";
import { OrganisationPicker } from "../organisations/org-picker";

type HeaderProps = {
  children?: React.ReactNode;
  showUpgradeButton?: boolean;
  showBackButton?: boolean;
};
export const Header = ({ children, showUpgradeButton = true, showBackButton = false }: HeaderProps) => {
  const { userPlan: data } = useGetUserPlan();
  const router = useRouter();
  const plan = data?.subscription_plan;
  const handleClick = () => {
    router.push("/");
  };
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: showBackButton ? "space-between" : "flex-end",
        }}
      >
        {showBackButton && (
          <Box>
            <Button startIcon={<ArrowLeft />} variant="text" onClick={handleClick}>
              Back
            </Button>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "100%" }}>{children}</Box>
          <Box>{showUpgradeButton && plan === UserSubscription.FREEMIUM && <UpgradeButton />}</Box>
          <OrganisationPicker />
        </Box>
      </Box>
    </Box>
  );
};
