"use client";

import { useUser } from "@clerk/nextjs";
import { Box, LinearProgress, Typography } from "@mui/material";
import { useGetUserPlan } from "~/app/hooks/useGetUserPlan";
import { SubscriptionService } from "~/services/subscriptions";

export const FreeTrialIndicator = () => {
  const { freeTrialInfo, isFreeTrial } = useGetUserPlan();
  const { user } = useUser();
  if (!isFreeTrial || !user) {
    return null;
  }
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: 200,
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          // gap: 0,
        }}
      >
        <Typography variant="caption" sx={{ display: "flex" }}>
          Free Trial
        </Typography>
        <Typography variant="caption" sx={{ display: "flex" }}>
          {freeTrialInfo.timeRemaining} days
        </Typography>
      </Box>
      <LinearProgress
        sx={{
          display: "flex",
          width: "100%",
          "& .MuiLinearProgress-root": {
            strokeLinecap: "round",
          },
        }}
        variant="determinate"
        value={Math.ceil((freeTrialInfo?.timeRemaining / SubscriptionService.getFreeTrialDays()) * 100)}
      />
    </Box>
  );
};
