import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/_pages/dashboard/v2/components/call-to-action-items.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/components/cards/profile.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/components/errors/error-boundary.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/components/layout/Header.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Box/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Grid/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Typography/index.js")