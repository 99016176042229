"use client";

import { OrganizationSwitcher, useOrganizationList } from "@clerk/nextjs";
import { Box } from "@mui/material";
import { useDarkMode } from "~/context/DarkmodeContext";
import { generateClerkTheme } from "~/styles/theme";
import { FreeTrialIndicator } from "../upgrade/indicator";
import { usePathname } from "next/navigation";
import { useGetUserPlan } from "~/app/hooks/useGetUserPlan";
import { SubscriptionService } from "~/services/subscriptions";

/**
 * Renders the OrganisationPicker component.
 *
 * The OrganisationPicker component is responsible for rendering a picker that allows users to select an organization.
 * It retrieves the user's plan, user memberships, and subscription addons to determine if the user can create a new organization.
 * It also handles the logic for redirecting to the organization profile page after selecting an organization.
 *
 * @returns The rendered OrganisationPicker component.
 */
export const OrganisationPicker = () => {
  const { mode } = useDarkMode();
  const pathname = usePathname();
  const { userPlan } = useGetUserPlan();
  const { userMemberships, isLoaded } = useOrganizationList({
    userMemberships: true,
  });
  const addons = userPlan?.subscription_addons;
  const orgAddon = SubscriptionService.getCreateOrgAddon(addons || []);

  const addonQty = orgAddon?.quantity || 0;

  const canCreateOrg =
    isLoaded &&
    (userMemberships.data?.length === 0 || (addonQty > 0 && addonQty > (userMemberships.data?.length || 0)));
  const isOnboarding = pathname === "/onboarding";

  // While we are onboarding we will not see this component.
  if (isOnboarding) return null;

  return (
    <Box
      sx={{
        display: "flex",
        padding: 3,
        width: "100%",
        gap: 3,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "end",
      }}
    >
      <FreeTrialIndicator />

      <OrganizationSwitcher
        hidePersonal
        createOrganizationUrl="/organisations/create"
        afterSelectOrganizationUrl={(org) => {
          window.location.href = window.location.origin;
          return "/";
        }}
        organizationProfileProps={{
          appearance: generateClerkTheme(mode),
        }}
        appearance={generateClerkTheme(mode, {
          showCreateOrgButton: canCreateOrg,
        })}
      />
    </Box>
  );
};
